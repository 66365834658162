import { contacts } from "../../../pages-data/_V2/common/contacts"

export default {
	title: "Если нужно открыть пиццерию как можно быстрее",
	mobileTitle: "Если нужно открыть пиццерию как можно быстрее",
	description:
		"Мы зарегистрируем онлайн-кассу, подключим оборудование и поможем заполнить данные в кабинете администратора. Начни работать, не отвлекаясь на «операционку».",
	phone: contacts.phone,
	ctaButtonText: "Заказать звонок",
}
