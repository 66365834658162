import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "../../../../pagesSections/automation/AvailableFeatures/mediaContent.module.scss"
import styles from "../../../../pagesSections/automation/AvailableFeatures/styles.module.scss"
import { pagesLinks } from "../../../_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Автономность",
			desktopTitle: "Автономность и офлайн режим",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Система автоматизации пицерии Quick Resto автономна и работает в
					отсутствии интернета и&nbsp;даже электричества. Будь уверен — все
					продажи и&nbsp;списания будут корректно учтены, когда появится связь с
					сервером.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="../../../../pagesSections/automation/AvailableFeatures/assets/automation-available-1.png"
					alt="касса для пиццерии"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			backdropContent: <div className={styles.feature1Backdrop} />,
		},
		{
			desktopContentWidth: 490,
			tabName: "Компактность",
			desktopTitle: "Скромные габариты",
			desktopContent: (
				<p>
					С Quick Resto комплект автоматизации не требует отдельной комнаты для
					сервера, проводов и драгоценной поверхности для установки кассы. Всё
					что нужно для начала работы — терминал на планшете для обслуживания
					гостей и необходимый по закону фискальный регистратор.
				</p>
			),
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="../../../../pagesSections/automation/AvailableFeatures/assets/automation-available-2.png"
					alt="система учёта для пиццерии"
					objectFit="cover"
					objectPosition="center 80%"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Мультиплатформенность",
			desktopTitle: (
				<span className={mediaContent.longWordTitle}>
					Мульти-платформенность
				</span>
			),
			desktopContent: (
				<p>
					Программу для пиццерии Quick Resto можно установить на&nbsp;различные
					платформы операционных систем: кассовый терминал работает на планшетах
					iOS, системах на&nbsp;ОС Windows, а также на терминалах Эвотор.
				</p>
			),
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="../../../../pagesSections/automation/AvailableFeatures/assets/automation-available-3.png"
					alt="программа учета для пиццерии"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			backdropContent: <div className={styles.feature3Backdrop} />,
		},
	],
}
