import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Техкарты",
			desktopTitle: "Техкарты и модификаторы",
			mobileTitle: "Техкарты и модификаторы",
			desktopContent: (
				<div className={mediaContent.smallDescription}>
					<p>
						Для корректного складского учета в пиццерии в&nbsp;Quick Resto
						используются технологические карты. Этот документ является
						основанием для расчета расхода продуктов и фактически определяет
						затраты предприятия на производство.
					</p>
					<p>
						Модификаторы позволяют менять свойства блюд, с&nbsp;их помощью можно
						«уточнять» способ приготовления или подачи блюда или продавать
						дополнительные компоненты к блюду. Возможность использования
						модификаторов является неотъемлемой частью современной системы
						автоматизации пиццерии. В Quick Resto можно работать с
						полуфабрикатами и&nbsp;модификаторами, учитывая различные сценарии
						работы с блюдами и продуктами.
					</p>
				</div>
			),
			detailLink: pagesLinks.menu,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/pizza-calculation-2.png"
					alt="программа для пиццерии"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Себестоимость",
			desktopTitle: "Себестоимость и калькуляция",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Каждая пиццерия имеет множество различных блюд, в которые входят
					разные ингредиенты, различные типы обработки, потери. Точные данные и
					оперативный учет позволяют оптимизировать производственные процессы
					и&nbsp;обеспечить стабильно высокую прибыль предприятия. Для
					корректного учета расхода продуктов, объема потерь и себестоимости
					блюд поможет система автоматизации Quick Resto.
				</p>
			),
			detailLink: pagesLinks.menu,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/pizza-calculation-3.png"
					alt="расчет себестоимости блюд"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
